import React from 'react'

/* Estilos con Material UI */
import { Grid, Paper, Typography, Chip } from '@material-ui/core';

const chipFormPago = (data, key, classes) =>{
    if (data.selected) {
        return (
            <Chip
                key={key}
                // color="primary"
                label={data.name}
                className={`${classes.chip} text-primary`}
            />
        )
    }
}

const FormaPago = (props) => (
    props.list && (

        <Grid item xs={12}>
            <Paper className="paper">
                <div className="title-paper relative">
                    <Typography color='primary' component="span" className="mb-06">Forma de pago de consulta</Typography>
                </div>
                <div>
                    {props.list.map(k => {
                        return (
                            chipFormPago(k, props.list.indexOf(k), props.classes)
                        )
                    })}

                    {props.list.length && 
                        props.list.filter(k => (k.name === 'Paypal'))[0].selected && 
                        <Chip
                            // color="primary"
                            className={`${props.classes.chip} text-primary`}
                            label='Tarjeta de débito/crédito'
                        />
                    }
                </div>
            </Paper>
        </Grid>
    )
)


export default FormaPago;