/* Url Desarrollo*/
// const base_url = 'http://localhost/ecardioline/apicardio/'
// const base_shared = 'http://localhost/ecardioline/cardioline/post/'

/* Url Calidad*/
const base_url = 'https://apicalidad.ecardioline.com/'
const base_shared = 'https://calidadsistema.ecardioline.com/post/'

// /* Url Produccion*/
// const base_url = 'https://apicardio.ecardioline.com/'
// const base_shared = 'https://ecardioline.com/post/'

export const apiUrl = {
    urlMedia: base_url,
    urlPost: base_url + 'public/post/',
    urlEPage: base_url + 'public/epage/',
    urlMedico: base_url + 'public/medico/',
    urlGeneral: base_url + 'public/general/',


    urlUser: base_url + 'public/user/',
    urlBanco: base_url + 'public/banco/',
    urlCitas: base_url + 'public/cita/',
    urlPais: base_url + 'public/country/',
    urlNotify: base_url + 'public/notify/',
    urlDirect: base_url + 'public/direct/',
    urlHistoria: base_url + 'public/historia/',
    urlAnalitica: base_url + 'public/analiticas/',
    urlIncidencia: base_url + 'public/incidencia/',
    urlHorario: base_url + 'public/horariomedico/',
    urlSharedPost: base_shared,
};