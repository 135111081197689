import React, { Component } from 'react';
import PropTypes from "prop-types";
// import { connect } from 'react-redux';

/**Actions */
// import { generalPublicActions } from '../../../../_actions';

/**Components */
import InfoMedico from "./infoMedico";
import Chips from "../../chips/chips";
import ProgressCircular from "../../progress/progressCircular";

/**Styles and Icon */
import { styles } from "../../../../AppStyle";
import { withStyles } from "@material-ui/core/styles";
import { Paper, Typography, Grid, Divider, Hidden } from '@material-ui/core';


class HomeBarleft extends Component {
    constructor(props) {
        super(props);
        this.state = {
            viewMasExtracto: false,
        }
    }

    handleVerMasExtracto = data => e => {
        e.preventDefault();
        this.setState({
            viewMasExtracto : !data
        })
    }

    render() {
        const { viewMasExtracto } = this.state
        const { infoMed, ruta, paciente } = this.props
        return (
            <Paper className='barleft' elevation={0}>
                <Paper className="paper card-extracto">
                    <div className="title-paper">
                        <Typography color='primary' component="span" className="nombre-user">{`${infoMed.name} ${infoMed.surname}`}</Typography>
                        <Typography conponent="span" color="primary" className="time-in-platafor">En eCARDIOLINE desde {infoMed.fech_reg}</Typography>
                    </div>
                    <div className='mb-05' >
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography conponent="span" color="textSecondary" dangerouslySetInnerHTML={{ __html: infoMed.extracto 
                                    ? !viewMasExtracto 
                                        ? infoMed.extracto.length > 100 ? `${infoMed.extracto.slice(0, 100)}...` : infoMed.extracto.slice(0, 100)
                                        : infoMed.extracto
                                    : ''
                                }}/>
                                
                                {infoMed.extracto &&
                                    infoMed.extracto.length > 100
                                        ? <Typography conponent="span" className='pointer' color="primary" onClick={this.handleVerMasExtracto(viewMasExtracto)}>
                                            {viewMasExtracto ? 'Leer menos' : 'Leer más'}
                                        </Typography>
                                        : ''
                                }
                            </Grid>
                        </Grid>
                    </div>
                    <Divider className='mb-05' />
                    <Chips
                        list={infoMed.especialidades}
                    />
                </Paper>

                {ruta === '/epage'
                    ? <Hidden smDown implementation="css">
                        {infoMed.preloader
                            ? <ProgressCircular
                                message='Cargando información...'
                            />
                            : <InfoMedico
                                infoMed={infoMed}
                                paciente={paciente}
                            />
                        }
                    </Hidden>
                    : ''
                }

                
                
            </Paper>
        );
    }
}

HomeBarleft.propTypes = {
    classes: PropTypes.object.isRequired,
};

// function mapStateToProps(state) {
//     const { blog } = state.generalPublicReducer;
//     return {
//         blog,
//     };
// }

// const connectedHomeBarleft = connect(mapStateToProps)(withStyles(styles)(HomeBarleft));
// export { connectedHomeBarleft as HomeBarleft }; 

export default withStyles(styles)(HomeBarleft);